import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './components/About';
import JKD from './components/JKD';
import Acupuncture from './components/Acupuncture';
import Herbs from './components/Herbs';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Home from './components/Home';
import './App.css';

const App: React.FC = () => {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <header className="App-header">
                    <div className="App-content">
                        <Routes>
                            <Route path="/about" element={<About />} />
                            <Route path="/acupuncture" element={<Acupuncture />} />
                            <Route path="/herbs" element={<Herbs />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/jkd" element={<JKD />} />
                            <Route path="/" element={<Home />} />
                        </Routes>
                    </div>
                </header>
            </div>
        </Router>
    );
};

export default App;
