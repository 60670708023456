import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

const config = {
  projectId: process.env.REACT_APP_SANITY_PROJECTID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  useCdn: false,
  apiVersion: '2024-07-24'
};

const client = createClient(config);

export const getJKD = async () => {
  try {
    const query = `*[_type == "jkd" && _id == $id][0]`;
    const params = { id: process.env.REACT_APP_SANITY_JKD_DOC_ID };
    const data = await client.fetch(query, params);

    return data;
  } catch (error) {
    console.error('Sanity fetch error:', error);
    throw error;
  }
}

const builder = imageUrlBuilder(client);

export const buildURL = (source) => builder.image(source);
