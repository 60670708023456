import { FC } from 'react';

const Home: FC = () => {
    return (
        <div>
            <h1>Welcome to Dragon Wellness</h1>
            <p>Now open in Monument, CO.</p>
        </div>
    );
};

export default Home;
