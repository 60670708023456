import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar: FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-links">
                <Link to='/' className="logo">
                    <div>
                        <img src='dragon_test.svg' alt="Logo" />
                        <p>DRAGON WELLNESS</p>
                    </div>
                </Link>
                <Link to="/about">About</Link>
                <Link to="/acupuncture">Acupuncture</Link>
                <Link to="/herbs">Herbs</Link>
                <Link to="/jkd">JKD</Link>
                <Link to="/contact">Contact</Link>
            </div>
            <div className='dropdown'>
                <div className='logo'>
                    <Link to='/'><img style={{ margin: '5px' }} src='dragon_test.svg' alt="Logo" /></Link>
                </div>
                <div className="hamburger" onClick={toggleDropdown}>
                    &#9776;
                </div>
            </div>
            <div className={`dropdown-content ${isOpen ? 'show' : ''}`}>
                <Link to="/about" onClick={toggleDropdown}>About</Link>
                <Link to="/acupuncture" onClick={toggleDropdown}>Acupuncture</Link>
                <Link to="/herbs" onClick={toggleDropdown}>Herbs</Link>
                <Link to="/jkd" onClick={toggleDropdown}>JKD</Link>
                <Link to="/contact" onClick={toggleDropdown}>Contact</Link>
            </div>
        </nav>
    );
};

export default Navbar;
