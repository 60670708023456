import { FC, useEffect, useState } from 'react';
import { buildURL, getJKD } from '../sanity';
import '../styles/JKD.css';

const JKD: FC = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getJKD();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="jkd-container">
      {data ? (
        <div>
          {data.image && <img src={buildURL(data.image.asset._ref).url()} alt={data.Header} className="jkd-image" />}
          <p className="jkd-text">{data.tagline}</p>
        </div>
      ) : (
        <p>No data found</p>
      )}
    </div>
  );
};

export default JKD;
