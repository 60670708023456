import { FC } from 'react';

const Acupuncture: FC = () => {
    return (
        <div>
            <h1>Acupuncture</h1>
            <p>Welcome to the Acupuncture page.</p>
        </div>
    );
};

export default Acupuncture;
